import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Splashscreen from '../views/Splashscreen.vue'
import PageNotFound from '../views/PageNotFound.vue'

/**
 * Attention, bien regarder https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations 
 * pour que l'accès à monsite.com/mapage fonctionne en direct (conf nginx & apache) 
 */

 Vue.use(VueRouter)

 const routes = [
 {
   path: '/',
   name: 'splashscreen',
   component: Splashscreen
 },
 {
  path: '/festival',
  name: 'Festival Subversif',
  component: Home
},
 { path: "*", component: PageNotFound }
]

const router = new VueRouter({
 mode: 'history',
 routes,
 scrollBehaviors() {
   return { x: 0, y: 0 }
 }
})

export default router
