<template>
  <div>
    <div class="row">
      <div class="col logo" v-on:click="goToHome()">
          <img alt="Logo du festival subversif" src="./../assets/logo.png">
      </div>
    </div><!--fin row -->
    <div class="row subtitle">
      <div class="col">
        <p>6&egrave;me &eacute;dition du 9 au 13 juin 2021</p>
      </div>
    </div>
  </div><!-- fin container-->
</template>

<script>
export default {

  name: 'Header',
  methods:{
        goToHome(){
          this.$root.$emit('refresh');
        }
    }

}
</script>

<style scoped>
.router-link-exact-active{
  text-decoration: none;
}

.logo{
  cursor:pointer
}

.logo img{
  width:100%;
  padding-top:10px;
}

@media (min-width: 700px) {
  .logo img {
		width: 700px;
  }
}

.subtitle{
  font-family: 'Bebas Neue';
  color:#30c1b5;
  font-size:3em;
}
</style>