
<template>
  <div class="row">
    <div class="col">
      <p>&#xA9; 2022 Festival du Film Subversif de Metz</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.row{
  margin-top:2em;
}
</style>