<template>
  <div id="splash" v-on:click="goToHome()">
      <div class="content container">
          
        <div id="txt">
            <img alt="Logo du festival subversif" src="./../assets/txt.png">
        </div>
        <div id="showme">ENTRER</div>
        <p id="dates">6eme édition<br/>Du 9 au 13 juin 2021</p>

        <div id="mamie">
            <img alt="Logo du festival subversif" src="./../assets/mamie.png">
        </div>
      </div>
  </div>
</template>

<script>
export default {    
    name: 'splashscreen',
    title:'Festival Subversif',
    methods:{
        goToHome(){
            this.$router.push('festival');
        }
    }
}
</script>

<style>


</style>

<style scoped lang="scss">

@keyframes animatecta {
  50% {opacity: 0;}
  75% {opacity: 05;}
  100% {opacity: 1;}
}

#showme {
  opacity: 0;
  animation: animatecta 0s 2s forwards;
  color:#30c1b5;
  font-family: 'Bebas Neue';
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(255,255,255,.7);
  padding: 1em;
  font-size: 2.5em;
}
#showme:hover, #showme:focus, #showme:active {  
  background-color: rgba(255,255,255,1);
  color:black;
}

@keyframes cssAnimation {
  to   { opacity: 1; }
}

#splash{
    width:100%;
    position: absolute;
    top:0;
    min-height:100vh;
    cursor: pointer;
    background:url("https://api.thumbr.io/whitenoise-361x370.png?background=ff00ff00&noise=ffffff&density=70&opacity=82"), linear-gradient(#f5fd41, #fff);
}
.content{
    padding:0;
    height:100%;
}

#txt{
    padding-top:2em;
    max-width:400px;
    text-align: left;
}
#dates{
    padding-left:20px;
    margin-top:1em;
   // border:1px solid orange;
    color:#30c1b5;
    font-family: 'Bebas Neue';
    text-align: left;
    text-transform: uppercase;
    font-size:1.9em;
    line-height:1.2em;
}

br {
   display: block;
   margin: 10px 0;
}

#mamie{
    //margin-top: calc(500px - 25vw);
    position:absolute;
    right:0;

    
}

@include media-breakpoint-only(xs) {
      .content{

        height:100vh;
    }
    #txt img{
        max-width: 100%;      
        max-height: 100%;
    }
    #mamie{

        right:0;
        text-align: right;
        bottom:0;
        img{
          max-height: 100vh;
          max-width:90%;
        }
    }
    #dates{
        max-width: 300px;
    }
}


@include media-breakpoint-only(sm) {
      .content{
        height:100vh;
    }
    #txt img{
        max-width:400px;        
    }
    #mamie{
        right:0;
        text-align: right;
        bottom:0;
        img{
          max-height: 100vh;
          max-width:90%;
        }
    }
    #dates{
        max-width: 300px;
    }
}


@include media-breakpoint-only(md) {
    .content{
        height:100vh;
    }
    #txt img{
        max-width:400px;        
    }
    #mamie{
        right:0;
        bottom:0;
        img{
          max-height: 100vh;
          width:auto;
        }
    }
    #dates{
        max-width: 300px;
    }
}


@include media-breakpoint-only(lg) {
    #txt{
        img{
            width: 500px;
        }
    }
    .content{
        height:100vh;
    }
  #mamie{
    right:0;
    text-align: right;
    // 1/2 container + 50vw - txt
    // = 960/2 - 400 + 50vw
    // = 80 + 50vw
    width: calc( 400px + 50vw);
    bottom:0;
    img{

        max-width: calc(400px + 50vw);
        max-height: 100vh;
    }
  }
}



@include media-breakpoint-up(xl) {
// xl = > 1200
// container : 1140px; 

// si je peux prendre tt à droite
// à partir de txt 
// alors 
// la largeur = ( COntainer - txt) + (100vw - container)/2
// = container - txt + 50vw - container/2
//= 1/2 container + 50vw - txt
// = 570 - 400 + 50vw
// = 170 + 50vw


    #txt{
        max-width:750px;

        img{
            max-width: 750px;
        }
    }
    .content{

        height:100vh;
    }
  #mamie{

    bottom:0;
    right:0;
    text-align: right;
    // left = 
        //left: calc(50vw - 170px);
    // 1/2 container + 50vw - txt
    // = 1140/2 - 400 + 50vw
    // = 170 + 50vw
    max-width: calc( 500px + 50vw);
    img{

        max-width: calc(500px + 50vw);
        max-height: 100vh;
    }
  }
}



</style>