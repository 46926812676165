<template>
  <div id="errorRoute">
      <p>Cette page n'existe plus ou a été déplacée.<br>Redirection en cours...</p>
  </div>
</template>

<script>
export default {    
    data() {
        return {
            prevRoute: null,            
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    mounted() {
        const nbSecondesAvantRedirect = 4;
        setTimeout(() => {
            if (this.prevRoute.name) {
                this.$router.back()
            }
            else {
                this.$router.push('/')
            }
        }, nbSecondesAvantRedirect*1000)
    }
}
</script>

<style lang="scss" scoped>

</style>