<template>
  <div id="app">
      <header class="container"  v-show="$route.name!=='splashscreen'"><Header></Header></header>
      <main v-bind:class="{ 'containeer': $route.name !=='splashscreen','container-fluid': $route.name !=='splashscreen' }"><router-view/></main>
      <footer class="container" v-show="$route.name!=='splashscreen'"><Footer></Footer></footer>
    
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'home',
  title:'Festival Subversif',
  components: {
    Header,
    Footer
  },
  watch: {
    '$route' (to) {
        document.title = to.title ? to.title : 'Festival Subversif';
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Bebas+Neue');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin-top: 20px;
  background-color: black;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main{
  flex:1;
}


</style>
